<template>
    <header class="header">
        <div class="header__left">
            <a class="header__logo" href="/">
                <img src="../assets/img/logos/default.svg" alt="" style="width: auto !important;">
                <span class="header__logo-text">Lnnx.pro</span>
            </a>
            <div class="header__links">
                <a class="header__link header__link-hot" href="/trading">{{
                    $t('interface.header.link1') }}</a>
                <a class="header__link" href="/profile/verification">{{ $t('interface.header.link3')
                }}</a>
            </div>
        </div>
        <div class="header__right">
            <div>
                <div class="lang__btn" @click="active.language = !active.language"
                    v-click-outside="() => active.language = false">
                    <span style="margin-right: 5px;" class="header__img-value">
                        <img :src="require(`../assets/img/flags/${selected[0].name}.png`)" alt="">
                    </span>
                    <div class="header__profile-link-icon" :class="{ 'header__profile-link-active': active.language }"
                        style="margin-right: 0 !important;">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <title>chevron-down</title>
                            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                        </svg>
                    </div>
                </div>
                <div class="header__profile-box" style="right: auto;"
                    :class="{ 'header__profile-box-active': active.language }">
                    <div class="header__profile-bottom">
                        <div v-for="item in languages" :key="item.code" class="header__link icon-size"
                            style="cursor: pointer;" @click="change(item.code)">
                            <span class="header__img-value">
                                <img :src="require(`../assets/img/flags/${item.name}.png`)" alt="">
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!loggedIn" class="header__sign-box" style="margin-left: 15px; margin-right: 15px;">
                <a class="header__btn-log" href="signin">Log in</a>
                <a class="header__btn-sign" href="signup">Sign up</a>
            </div>

            <a v-if="loggedIn" class="header__link wallet-btn" href="/profile/wallet">
                {{ balance.available | numeralFormat('0.[00]') }} USD <div class="header__profile-link-icon wallet-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="currentColor"
                            d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                </div>
            </a>

            <div v-if="loggedIn" class="header__profile">
                <div class="header__profile-toggle" @click="active.profile = !active.profile"
                    v-click-outside="() => active.profile = false">
                    <div class="header__toggle-name">{{ user.username }}</div>
                    <div class="header__toggle-img">
                        <img src="../assets/img/profile/avatar.svg" alt="">
                    </div>
                    <div class="header__toggle-btn">
                        <svg width="28" height="28" viewBox="0 0 100 100">
                            <path class="header__nav-line header__nav-line1"
                                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058">
                            </path>
                            <path class="header__nav-line header__nav-line2" d="M 20,50 H 80"></path>
                            <path class="header__nav-line header__nav-line3"
                                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942">
                            </path>
                        </svg>
                    </div>
                </div>

                <div class="header__profile-box" :class="{ 'header__profile-box-active': active.profile }">
                    <div class="header__profile-top">
                        <div class="header__profile-img ">
                            <img style="width: 64px; height: 64px; border-radius: 50%;"
                                src="../assets/img/profile/avatar.svg" alt="">
                        </div>
                        <div class="header__profile-info">
                            <div class="header__profile-name">{{ user.username }}</div>
                            <div class="header__profile-email">{{ user.email }}</div>
                        </div>
                    </div>
                    <div class="header__profile-center">
                        <a class="header__profile-link header__link-hot" href="/trading">{{
                            $t('interface.header.link1') }}</a>
                        <a class="header__profile-link" href="/profile/verification">{{
                            $t('interface.header.link3') }}</a>
                    </div>
                    <div class="header__profile-bottom">
                        <a class="header__profile-link" href="/profile/wallet">
                            <div class="header__profile-link-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M21,18V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V6H12C10.89,6 10,6.9 10,8V16A2,2 0 0,0 12,18M12,16H22V8H12M16,13.5A1.5,1.5 0 0,1 14.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,12A1.5,1.5 0 0,1 16,13.5Z">
                                    </path>
                                </svg>
                            </div>
                            <span>{{ $t('interface.header.link4') }} {{ balance.available | numeralFormat('0.[00]') }}
                                USD </span>
                        </a>
                        <a class="header__profile-link" href="/profile/settings">
                            <div class="header__profile-link-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z">
                                    </path>
                                </svg>
                            </div>
                            <span>{{ $t('interface.header.link5') }}</span>
                        </a>
                        <div class="header__profile-link header__profile-out" @click="logout()">
                            <div class="header__profile-link-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13">
                                    </path>
                                </svg>
                            </div>
                            <span>{{ $t('interface.header.link6') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            active: {
                profile: false,
                language: false,
            },
            languages: [
                { code: 'UK', name: 'United Kingdom' },
                { code: 'RU', name: 'Russian Federation' },
                { code: 'DE', name: 'Germany' },
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user
        },
        balance() {
            return this.$store.state.balance.balance
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        },
        selected() {
            return this.languages.filter(item => item.code === this.$i18n.locale)
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logout')
            window.location.replace(CONFIG.BASEURL + '/signin')
        },
        change(code) {
            this.$i18n.locale = code
            localStorage.setItem('lang', code)
        },
    }
}
</script>

<style scoped>
.wallet-btn {
    margin-left: 13px;
    margin-right: 16px;
    /* height: 18px;
    width: 27px; */
    padding: 6px 10px 6px 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffce46;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    border-radius: 3px;
    background-color: #ffce4610;
}

.wallet-btn:hover {
    color: #ffce46cc;
}

.wallet-btn:hover .wallet-icon {
    color: #ffce46cc;
}

.wallet-icon {
    margin-left: 5px;
    width: 11px;
    height: 11px !important;
    color: #ffce46;
    margin-right: 0;
    margin-bottom: 1px;
    transition: color .3s ease-in-out;
}

.header__profile-out {
    cursor: pointer;
}

.header__profile-out:hover {
    color: #ff231fa4
}

.icon-size {
    padding: 0.2rem 0.7rem;
    transition: opacity .3s ease-in-out;
}

.icon-size:hover {
    opacity: .62;
}

.lang__btn {
    display: flex;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
}

.lang__btn:hover {
    opacity: .62;
}

.header__img-value img {
    width: 17px;
    height: 12px;
}

.header__sign-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header__btn-log,
.header__btn-sign {
    color: #fff;
    padding: 9px 14px;
    font-weight: 500;
}

.header__btn-sign {
    background-color: #007eda;
    border-radius: 5px;
}
</style>
